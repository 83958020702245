export const withComponentFeatures = (component) => {

    const getComponentData = (id) => {
        return new Promise((resolve, reject) => {
            UniversalDashboard.get(`/api/internal/component/element/${id}`, (data) => {
                if (data.error) reject(data.error.message);
                else resolve(data)
            });
        });
    };

    const sendComponentState = (requestId, state) => {
        return new Promise((resolve, reject) => {
            UniversalDashboard.post(`/api/internal/component/element/sessionState/${requestId}`, state, (data) => {
                if (data.error) reject(data.error.message);
                else resolve(data)
            });
        });
    }

    const post = (id, data) => {
        return new Promise((resolve, reject) => {
            UniversalDashboard.post(`/api/internal/component/element/${id}`, data, (returnData) => {
                resolve(returnData)
            });
        });
    }

    const get = (id) => {
        return new Promise((resolve, reject) => {
            UniversalDashboard.get(`/api/internal/component/element/${id}`, (returnData) => {
                resolve(returnData)
            });
        });
    }

    const postWithHeaders = (id, data, headers) => {
        return new Promise((resolve, reject) => {
            UniversalDashboard.postWithHeaders(`/api/internal/component/element/${id}`, data, (returnData) => {
                resolve(returnData)
            }, headers);
        });
    }

    const subscribeToIncomingEvents = (id, callback) => {
        const incomingEvent = (id, event) => {

            let type = event.type;
            if (type === "requestState") {
                type = "getState"
            }

            callback(type, event);
        }

        return UniversalDashboard.subscribe(id, incomingEvent);
    }

    const unsubscribeFromIncomingEvents = (token) => {
        UniversalDashboard.unsubscribe(token)
    }

    function isString(obj) {
        return (Object.prototype.toString.call(obj) === '[object String]');
    }

    const render = (component, history) => {
        if (!isString(component)) {
            // set props version
            if (!component.__version) {
                component.__version = "0";
            }

            if (!history && component.history) {
                history = component.history;
            }
        }

        return UniversalDashboard.renderComponent(component, history);
    }

    const highOrderComponent = (props) => {
        const [componentState, setComponentState] = react.useState(props);
        const componentRef = react.useRef(props);

        react.useEffect(() => {
            setComponentState({ ...props });
        }, [props.__version])

        const notifyOfEvent = (eventName, value) => {
            UniversalDashboard.publish('element-event', {
                type: "clientEvent",
                eventId: props.id + eventName,
                eventName: eventName,
                eventData: value
            });
        }

        const incomingEvent = (type, event) => {
            if (type === "setState") {
                componentRef.current = { ...componentRef.current, ...event.state }
                setComponentState(existingState => { return { ...existingState, ...event.state } });
            }

            if (type === "getState") {
                event.callback(componentRef.current);
            }

            if (type === "addElement") {
                let children = componentState.children;
                if (children == null) {
                    children = []
                }

                if (!Array.isArray(children)) {
                    children = [children]
                }

                children = children.concat(event.elements);

                componentRef.current = { ...componentRef.current, children }
                setComponentState(existingState => { return { ...existingState, children } });
            }

            if (type === "clearElement") {
                componentRef.current = { ...componentRef.current, children: [] }
                setComponentState(existingState => { return { ...existingState, children: [] } });
            }

            if (type === "removeElement") {
                // This isn't great
                componentRef.current = { ...componentRef.current, hidden: true }
                setComponentState(existingState => { return { ...existingState, hidden: true } });
            }

            if (type === "syncElement") {
                setComponentState(existingState => { return { ...componentRef.current, __version: Math.random().toString(36).substr(2, 5) } });
            }
        }

        react.useEffect(() => {
            const token = subscribeToIncomingEvents(props.id, incomingEvent)
            return () => {
                unsubscribeFromIncomingEvents(token)
            }
        });

        const newEndpoint = (endpoint) => {
            return (data, options) => {
                if (endpoint.websocket) {
                    UniversalDashboard.publish('element-event', {
                        type: "clientEvent",
                        eventId: endpoint.name,
                        eventName: endpoint.name,
                        eventData: data
                    });
                    return {};
                }
                else {
                    let headers = {}
                    if (endpoint.accept && endpoint.accept !== '') {
                        headers['Accept'] = endpoint.accept;
                    }

                    if (endpoint.contentType && endpoint.contentType !== '') {
                        headers['Content-Type'] = endpoint.contentType;
                    }

                    let url = endpoint.name;
                    if (options && options.query) {
                        url = url + '?' + options.query;
                    }

                    return postWithHeaders(url, data, headers);
                }
            }
        }

        const additionalProps = {
            render,
            setState: (state, refOnly) => {
                componentRef.current = {
                    ...componentRef.current,
                    ...state
                };

                if (refOnly) return;

                setComponentState(existingState => {
                    return {
                        ...existingState,
                        ...state
                    }
                });
            },
            publish: UniversalDashboard.publish,
            notifyOfEvent,
            post,
            get,
            subscribeToIncomingEvents,
            unsubscribeFromIncomingEvents,
            newEndpoint
        }

        Object.keys(componentState).forEach(x => {
            if (componentState[x] != null && componentState[x].endpoint) {
                const endpoint = componentState[x];
                additionalProps[x] = newEndpoint(endpoint)
            }
        })

        if (componentState.hidden) {
            return react.createElement(react.Fragment);
        }

        return component({ ...componentState, ...additionalProps })
    }

    return highOrderComponent;
}

export const registerComponent = (id, component) => {
    UniversalDashboard.register(id, component);
}
