import React, { useState, useEffect, useReducer } from 'react';
import Alert from '@mui/material/Alert';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import { withComponentFeatures } from 'universal-dashboard';
import Skeleton from '@mui/material/Skeleton';
import { FormContext } from './form';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import UDIcon from './icon';
import { StepIconProps } from "@mui/material/StepIcon";
import { styled } from '@mui/material/styles';

const reducer = (state, action) => {
    switch (action.type) {
        case 'changeField':
            var newState = { ...state };
            newState[action.id] = action.value;
            return newState;
        default:
            throw new Error();
    }
}

const UDStepImpl = (props) => {

    const [content, setContent] = useState(null);

    useEffect(() => {
        props.onLoad({ context: props.context }).then(x => {

            try {
                x = JSON.parse(x);
            } catch { }

            setContent(x);
        })
    }, [true]);

    if (content == null) {
        return <Skeleton />
    }

    return (
        props.render(content)
    )
}




const UDStepperImpl = (props) => {
    const [fields, setFields] = useReducer(reducer, {});
    const [content, setContent] = useState(null);
    const [validating, setValidating] = useState(false);
    const [valid, setValid] = useState(true);
    const [validationError, setValidationError] = useState('');
    const [disablePrevious, setDisablePrevious] = useState(false);
    const [previousStepIndex, setPreviousStepIndex] = useState(0);

    if (content) {
        return props.render(content);
    }

    let handleStep = (index) => {
        setPreviousStepIndex(props.activeStep);
        props.setState({ activeStep: index })
    }

    let handleNext = () => {
        if (props.activeStep === props.children.length - 1) {
            props.onFinish({ ...fields, context: fields, currentStep: props.activeStep }, { query: "form=true" }).then(x => {
                var json = JSON.parse(x);
                setContent(json);
            });
        }
        else {
            setPreviousStepIndex(props.activeStep);
            props.setState({ activeStep: props.activeStep + 1 })
        }
    };

    const handleBack = () => {
        setValid(true);
        setValidationError('');
        setPreviousStepIndex(previousStepIndex - 1);
        props.setState({ activeStep: previousStepIndex })
    };

    if (props.onValidateStep) {

        const handleNextAfterValid = handleNext;

        handleNext = () => {
            setValidating(true);
            props.onValidateStep({ ...fields, context: fields, currentStep: props.activeStep }, { query: "form=true" }).then(x => {
                setValidating(validating);
                var json = JSON.parse(x);

                if (Array.isArray(json)) {
                    json = json[0]
                }

                setValid(json.valid);
                setValidationError(json.validationError);
                setDisablePrevious(json.disablePrevious);

                if (json.context) {
                    Object.keys(json.context).forEach(x => {
                        setFields({
                            type: 'changeField',
                            id: x,
                            value: json.context[x]
                        });
                    })
                }

                if (json.valid) {
                    handleNextAfterValid();
                }

                if (json.activeStep != -1) {
                    setPreviousStepIndex(props.activeStep);
                    props.setState({ activeStep: json.activeStep })
                }
            });
        }
    }

    const currentStep = props.children[props.activeStep];

    const activeStep = props.render({ ...props.children[props.activeStep], context: fields });

    const contextState = {
        onFieldChange: (field) => {
            setFields({
                type: 'changeField',
                ...field
            });
        }
    }

    const stepContent = <div style={{ padding: '20px' }}>
        {activeStep}
        {valid ? <React.Fragment /> : <div style={{ color: 'red' }} id={props.id + "-validationError"}><UDIcon icon="Exclamation" /> {validationError}</div>}
        <div style={{ padding: '20px' }}>
            {props.onCancel && <Button onClick={() => props.onCancel()}>{props.cancelButtonText}</Button>}
            <Button
                disabled={props.activeStep === 0 || disablePrevious || currentStep.disablePrevious}
                onClick={handleBack}
                id={props.id + "btnPrev"}
            >
                {props.backButtonText}
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext} id={props.id + "btnNext"}>
                {validating ?
                    <CircularProgress size={14} /> :
                    <Typography> {props.activeStep === props.children.length - 1 ? props.finishButtonText : props.nextButtonText}</Typography>}
            </Button>
        </div>
    </div>

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                `linear-gradient( 136deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 100%)`,
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                `linear-gradient( 136deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 100%)`,
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className, icon } = props;

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icon}
            </ColorlibStepIconRoot>
        );
    }


    return (
        <FormContext.Provider value={contextState}>
            <Stepper
                activeStep={props.activeStep}
                id={props.id}
                orientation={props.orientation}
                className={props.className}
                alternativeLabel={props.alternativeLabel} nonLinear={props.nonLinear}
                style={props.style}
                sx={props.sx}
            >
                {!props.children?.map && <Alert severity='warning'>This stepper has no steps.</Alert>}
                {props.children?.map && props.children.map((step, index) => {

                    const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => {
                        if (theme.palette.mode === 'dark') {
                            return step.darkIconStyle || step.iconStyle;
                        }
                        return step.iconStyle;
                    });

                    function CustomStepIcon(props) {
                        const { active, completed, className, icon } = props;

                        return (
                            <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
                                {icon}
                            </CustomStepIconRoot>
                        );
                    }

                    let stepIconComponent = null;
                    if (step.icon && step.iconStyle) {
                        stepIconComponent = CustomStepIcon;
                    } else if (step.icon && !step.removeIconStyle) {
                        stepIconComponent = ColorlibStepIcon;
                    }


                    const label = <StepLabel icon={(step.icon) ? <UDIcon {...step.icon} /> : null} StepIconComponent={stepIconComponent} error={!valid && index === props.activeStep}>{step.label}</StepLabel>;

                    return <Step key={step.label} >
                        {props.nonLinear ? <StepButton color="inherit" onClick={() => handleStep(index)}>
                            {label}
                        </StepButton> : label
                        }
                        {props.orientation === "vertical" && index == props.activeStep ? <StepContent>{stepContent}</StepContent> : <React.Fragment />}
                    </Step>
                }
                )}
            </Stepper>
            {props.orientation === "horizontal" ? stepContent : <React.Fragment />}
        </FormContext.Provider>
    )
}

export const UDStepper = withComponentFeatures(UDStepperImpl);
export const UDStep = withComponentFeatures(UDStepImpl);