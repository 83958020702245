import React from 'react';
import Typography from '@mui/material/Typography';
import UDIcon from '../icon';
import { Redirect } from 'react-router-dom'
import Markdown from "react-markdown";

const NotRunning = () => {

    const [redirect, setRedirect] = React.useState(false);
    const [description, setDescription] = React.useState("App is not running");
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        var token = setInterval(() => {
            UniversalDashboard.get('/api/internal/dashboard', function (json) {
                setRedirect(true);
            });
        }, 5000);

        UniversalDashboard.get('/api/v1/settings/public', function (data) {
            const settings = data ? data[0] : null;
            if (settings?.branding?.appNotRunningDescription)
                setDescription(settings?.branding?.appNotRunningDescription);

            setLoading(false);
        });

        return () => { clearInterval(token) }
    }, [true]);

    if (redirect) {
        return <Redirect to="/" />
    }

    if (loading)
        return <div></div>

    return (
        <div style={{ textAlign: 'center', marginTop: '20%' }}>
            <Typography variant="h1" id="not-found"><UDIcon icon="Exclamation" /><Markdown children={description} /></Typography>
        </div>
    )
}

export default NotRunning;