import React, { useState, useEffect } from 'react';
import ErrorCard from './error-card.jsx';
import ReactInterval from 'react-interval';
import { withComponentFeatures } from 'universal-dashboard';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import { PageContext } from '../../app/PageContext.jsx';
import { AppContext } from '../../app/app-context.jsx';
import { WidthProvider, Responsive } from "react-grid-layout";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
require('react-grid-layout/css/styles.css');
require('react-resizable/css/styles.css');


const useStyles = makeStyles((theme) => ({
    main: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: (props) => props.navigationLayout === "permanent" ? "64px" : null,
        height: "initial"
    }
}));

const UDPageWithContext = (props) => {
    return (
        <PageContext.Consumer>
            {context => {
                return <UDPage {...props} context={context} />
            }}
        </PageContext.Consumer>
    )
}

const UDPage = (props) => {
    const { context } = props;
    const classes = useStyles({
        navigationLayout: props.navLayout || context.navigationLayout
    });

    const [components, setComponents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { developerLicense } = React.useContext(AppContext);

    const loadData = () => {
        if (!props.match) {
            return;
        }

        var queryParams = {
            "query": "true"
        };

        const searchParams = new URLSearchParams(window.location.search);

        for (const [key, value] of searchParams.entries()) {
            queryParams[key] = value;
        }

        for (var k in props.match.params) {
            if (props.match.params.hasOwnProperty(k)) {
                queryParams[k] = props.match.params[k];
            }
        }

        var esc = encodeURIComponent;
        var query = Object.keys(queryParams)
            .map(k => esc(k) + '=' + esc(queryParams[k]))
            .join('&');

        let propsTitle = props.title;
        if (!props.title || props.title === "") {
            propsTitle = props.name;
        }

        document.title = propsTitle;

        props.setPageState(state => {
            return {
                ...state,
                blank: props.blank,
                rootClass: classes.root,
                title: propsTitle || state.title,
                navigation: props.navigation || context.navigation,
                navigationLayout: props.navLayout,
                hideNavigation: props.hideNavigation || props.loadNavigation,
                logo: props.logo,
                headerColor: props.headerColor,
                headerBackgroundColor: props.headerBackgroundColor,
                headerPosition: props.headerPosition,
                hideUserName: props.hideUserName || state.hideUserName,
                displayHeader: !props.blank,
                icon: props.icon
            }
        });

        UniversalDashboard.get(`/api/internal/component/element/${props.id}?${query}`, async json => {
            if (json.error) {
                setErrorMessage(json.error.message);
                setHasError(true);
            }
            else {
                setComponents(json);
                setHasError(false);
            }

            let headerContent = null;
            if (props.headerContent) {
                const headerContentJson = await props.headerContent();
                const hContent = JSON.parse(headerContentJson);
                headerContent = hContent;
            }

            let navigation;
            if (props.loadNavigation) {
                const loadNavigationContent = await props.loadNavigation();
                const nav = JSON.parse(loadNavigationContent);
                navigation = nav;
            }

            var title;
            if (props.loadTitle) {
                const nameJson = await props.loadTitle();
                title = props.render(nameJson);
                if (typeof (title) === 'string') {
                    document.title = title;
                }
            }

            props.setPageState(state => {
                return {
                    ...state,
                    headerContent: headerContent,
                    navigation: navigation || props.navigation || context.navigation || state.navigation,
                    hideNavigation: props.hideNavigation,
                    displayHeader: !props.blank,
                    title: title || state.title
                }
            })

            setLoading(false);
        });
    }

    useEffect(() => {
        loadData();
        return () => { }
    }, [true])

    if (hasError) {
        return <ErrorCard message={errorMessage} id={props.id} title={"An error occurred on this page"} />
    }

    if (loading) {
        if (props.onLoading) {
            return props.render(props.onLoading);
        }

        return <main className={classes.main}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </main>
    }

    var childComponents = props.render(components);
    if (props.layout) {
        childComponents = <ResponsiveReactGridLayout
            isDraggable={false}
            measureBeforeMount={true}
            isResizable={false}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 36, md: 10, sm: 6, xs: 4, xxs: 2 }}
            rowHeight={5}
            layouts={props.layout}
        >
            {components.map(m => <div key={m.id}>{props.render(m)}</div>)}
        </ResponsiveReactGridLayout>
    }

    let backgroundStyle = {};
    if (props.backgroundImage) {
        if (props.backgroundRepeat) {
            backgroundStyle = { backgroundImage: `url(${props.backgroundImage})`, height: "100%" };
        }
        else {
            backgroundStyle = { backgroundImage: `url(${props.backgroundImage})`, height: "100%", backgroundSize: "cover" };
        }
    }

    let content = null;
    if (props.blank) {
        content = <div className={classes.root}>
            {childComponents}
            {developerLicense && <div style={{ position: "absolute", bottom: 10, right: 10, color: "red" }}>This instance is running a developer license.</div>}
            <ReactInterval timeout={props.refreshInterval * 1000} enabled={props.autoRefresh} callback={loadData} />
        </div>
    }
    else {
        content = <>
            <main className={classes.main} style={backgroundStyle}>
                {childComponents}
                {developerLicense && <div style={{ position: "absolute", bottom: 0, right: 0, color: "red" }}>This instance is running a developer license.</div>}
            </main>
            <ReactInterval timeout={props.refreshInterval * 1000} enabled={props.autoRefresh} callback={loadData} />
        </>
    }

    return content;
}

export default withComponentFeatures(UDPageWithContext);
