import React, { useState, useEffect, useRef } from 'react'
import PubSub from 'pubsub-js'

import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import toaster from './services/toaster'
import copy from 'copy-to-clipboard'
import useErrorBoundary from 'use-error-boundary';
import { AppContext } from './app-context';
import { useIdleTimer } from 'react-idle-timer';
import Button from '@mui/material/Button'
import ReactInterval from 'react-interval';
import { Alert, AlertTitle, CssBaseline, GlobalStyles } from "@mui/material";
import { Helmet } from "react-helmet";
import useWebSocket from './websocket.jsx';
import ReadHostModal from './ReadHostModal';
import ProgressSnackbar from './ProgressSnackbar';
import DebugModal from './debug-modal';
import { getDashboardId } from './config.jsx'
const dashboardId = getDashboardId();
import { useSnackbar } from 'notistack'

function loadStylesheet(url) {
  var styles = document.createElement('link')
  styles.rel = 'stylesheet'
  styles.type = 'text/css'
  styles.media = 'screen'
  styles.href = url
  document.getElementsByTagName('head')[0].appendChild(styles)
}

function loadJavascript(url, onLoad) {
  var jsElm = document.createElement('script')
  jsElm.onload = onLoad
  jsElm.type = 'application/javascript'
  jsElm.src = url
  document.body.appendChild(jsElm)
}

function getLocation(setLocation) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var name = 'location'

      var positionJson = {
        coords: {
          accuracy: position.coords.accuracy,
          altitude: position.coords.altitude,
          altitudeAccuracy: position.coords.altitudeAccuracy,
          heading: position.coords.heading,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          speed: position.coords.speed,
        },
        timestamp: new Date(position.timestamp).toJSON(),
      }

      var value = JSON.stringify(positionJson)
      value = btoa(value)
      document.cookie = name + '=' + (value || '') + '; path=/'

      setLocation(value)
    })
  }
}

function Dashboard({ history }) {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary()

  const [dashboard, setDashboard] = useState(null)
  const [dashboardName, setDashboardName] = useState('')
  const [dashboardError, setDashboardError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [location, setLocation] = useState(null);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState('');
  const [idleTimeout, setIdleTimeout] = useState(Number.MAX_VALUE);
  const [sessionTimedOut, setSessionTimedOut] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const defaultTheme = localStorage.getItem('theme');
  const [theme, setTheme] = useState(defaultTheme ? defaultTheme : 'light');
  const [currentTheme, setCurrentTheme] = useState({});
  const [readHost, setReadHost] = useState({});
  const [readHostOpen, setReadHostOpen] = useState(false);
  const [progress, setProgress] = useState({});
  const [progressOpen, setProgressOpen] = useState(false);
  const [debugOpen, setDebugOpen] = useState(false);
  const [debugState, setDebugState] = useState(false);
  const [authType, setAuthType] = useState('');
  const [selectedComponent, setSelectedComponent] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [developerLicense, setDeveloperLicense] = useState(false);
  const [modalContent, setModalContent] = useState(<div id="sessionTimedOut">Your session has timed out</div>);

  const modalFooter = [<Button onClick={() => window.location.reload()}>Refresh Page</Button>];

  const websocket = useRef();

  useIdleTimer({
    crossTab: false,
    stopOnIdle: true,
    onIdle: () => {
      if (!sessionId) return;
      UniversalDashboard.disableFetchService();
      UniversalDashboard.publish('modal.open', {
        header: [<div />],
        content: [<div id="idleTimeout">This page has been disabled due to it being idle.</div>],
        footer: [<Button onClick={() => window.location.reload()}>Refresh Page</Button>]
      })
    },
    timeout: 1000 * 60 * idleTimeout
  })

  const checkSession = () => {
    if (!sessionId) return;
    UniversalDashboard.get(`/api/internal/session/${sessionId}`, () => { }, null, () => {
      UniversalDashboard.disableFetchService();
      UniversalDashboard.publish('modal.open', {
        content: [modalContent],
        footer: modalFooter
      })

      setSessionTimedOut(true);
    })
  }

  const loadData = () => {
    UniversalDashboard.get('/api/v1/alive', (json) => {
      setDeveloperLicense(json.developerLicense);
    });

    UniversalDashboard.get('/api/internal/dashboard',
      function (json) {
        var dashboard = json.dashboard
        var dashboardName = json.dashboardName;

        if (dashboard.error) {
          setDashboardError(dashboard.error);
          return;
        }

        if (dashboard.stylesheets) dashboard.stylesheets.map(loadStylesheet)
        if (dashboard.scripts) dashboard.scripts.map(loadJavascript)

        websocket.current = useWebSocket(json.sessionId, json.pageId, () => setLoading(false), (connection) => {

          connection.on('debugger', json => {
            var data = JSON.parse(json);

            setDebugOpen(true);
            setDebugState(data);
          })

          connection.on('setState', json => {

            var data = JSON.parse(json);

            PubSub.publish(data.componentId, {
              type: 'setState',
              state: data.state,
            })
          })

          connection.on('showToast', json => {
            var model = JSON.parse(json);
            toaster.show(model)
          })

          connection.on('showSnackbar', json => {
            var model = JSON.parse(json);
            enqueueSnackbar(model.message, model);
          })

          connection.on('hideSnackbar', id => {
            if (id) {
              closeSnackbar(id);
            } else {
              closeSnackbar();
            }
          })

          connection.on('showError', json => {
            var model = JSON.parse(json);
            toaster.error(model)
          })

          connection.on('hideToast', id => {
            toaster.hide(id)
          })

          connection.on('setTheme', async theme => {
            var theme = JSON.parse(theme);
            if (theme.name) {
              const response = await fetch(`/api/v1/dashboard/theme/${theme.name}`);
              const themeJson = await response.json();
              setCurrentTheme(themeJson);
              localStorage.setItem('ctheme' + dashboardId, JSON.stringify(themeJson));
            } else if (theme.theme) {
              setCurrentTheme(JSON.parse(theme.theme));
              localStorage.setItem('ctheme' + dashboardId, theme.theme);
            }

            if (theme.variant) {
              setTheme(theme.variant);
            }
          })

          connection.on("getTheme", async () => {
            return theme
          });

          connection.on('requestState', json => {
            let result = "null";

            var data = JSON.parse(json)

            PubSub.publishSync(data.componentId, {
              type: 'requestState',
              requestId: data.requestId,
              callback: (state) => { result = state; }
            });

            return JSON.stringify(result);
          })

          connection.on('invoke', json => {
            var data = JSON.parse(json)

            PubSub.publish(data.id, {
              type: 'invoke',
              data: data,
            })
          })

          connection.on('invokeMethod', json => {
            var data = JSON.parse(json)

            PubSub.publish(data.id, {
              ...data,
              type: 'invokeMethod',
            })
          })

          connection.on('removeElement', json => {

            var data = JSON.parse(json);

            PubSub.publish(data.componentId, {
              type: 'removeElement',
              componentId: data.componentId,
              parentId: data.parentId,
            })
          })

          connection.on('clearElement', componentId => {
            PubSub.publish(componentId, {
              type: 'clearElement',
              componentId: componentId,
            })
          })

          connection.on('syncElement', componentId => {
            PubSub.publish(componentId, {
              type: 'syncElement',
              componentId: componentId,
            })
          })

          connection.on('testForm', componentId => {
            PubSub.publish(componentId, {
              type: 'testForm',
              componentId: componentId,
            })
          })

          connection.on('addElement', json => {
            var data = JSON.parse(json);

            PubSub.publish(data.componentId, {
              type: 'addElement',
              componentId: data.componentId,
              elements: data.elements,
            })
          })

          connection.on('showModal', json => {
            var props = JSON.parse(json);
            PubSub.publish('modal.open', props)
          })

          connection.on('closeModal', () => {
            PubSub.publish('modal.close', {})
          })

          connection.on('readHost', (json) => {
            const data = JSON.parse(json);
            setReadHost(data);
            setReadHostOpen(true);
          })

          connection.on('refresh', () => {
            setTimeout(() => window.location.reload(), 500);
          })

          connection.on('select', json => {

            var data = JSON.parse(json);
            document.getElementById(data.id).focus()
            if (data.scrollToElement) {
              document.getElementById(data.id).scrollIntoView()
            }
          })

          connection.on('invokejavascript', async jsscript => {
            eval(jsscript)
          })

          connection.on('invokejavascriptreturn', async jsscript => {
            var result = eval(jsscript)
            if (result !== undefined) {
              return JSON.stringify(result);
            } else {
              return "";
            }
          })

          connection.on('progress', json => {
            var data = JSON.parse(json);
            if (data.completed) {
              setProgressOpen(false);
            } else {
              setProgressOpen(true);
              setProgress(data);
            }
          })


          connection.on('clipboard', json => {

            var data = JSON.parse(json);
            try {
              let isCopyed = data.data !== null || data !== '' ? copy(data.data) : false
              if (data.toastOnSuccess && isCopyed) {
                toaster.show({
                  message: 'Copied to clipboard',
                })
              }
            } catch (err) {
              if (data.toastOnError) {
                toaster.show({
                  message: 'Unable to copy to clipboard',
                })
              }
            }
          })

          connection.on('download', json => {
            var data = JSON.parse(json);

            var element = document.createElement('a');
            element.setAttribute('href', `data:${data.contentType};charset=utf-8,` + encodeURIComponent(data.stringData));
            element.setAttribute('download', data.fileName);
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          })

          connection.on('write', message => {
            PubSub.publish('write', message)
          })

          connection.on('log', message => {
            console.log(message);
          })

          PubSub.subscribe('element-event', function (e, data) {
            if (data.type === 'clientEvent') {
              connection
                .invoke(
                  'clientEvent',
                  data.eventId,
                  data.eventName,
                  data.eventData,
                  location,
                )
                .catch(function (e) {
                  toaster.show({
                    message: e.message,
                    icon: 'fa fa-times-circle',
                    iconColor: '#FF0000',
                  })
                })
            }

            if (data.type === 'getState') {
              connection.invoke('getState', data.requestId, JSON.stringify(data.state))
            }
          })

          connection.on('redirect', json => {
            var data = JSON.parse(json);

            if (data.url.startsWith('/') && !data.native) {
              history.push(data.url);
              PubSub.publish('navbar.close', {})
            }
            else if (data.openInNewWindow) {
              window.open(data.url)
            } else {
              window.location.href = data.url
            }
          })
        });

        UniversalDashboard.sessionId = json.sessionId;
        UniversalDashboard.connectionId = json.pageId;

        setDashboard(dashboard);
        setDashboardName(dashboardName);
        var dashboardTheme = JSON.parse(dashboard.theme);

        var storedTheme = localStorage.getItem("ctheme" + dashboardId);
        setCurrentTheme(storedTheme ? JSON.parse(storedTheme) : dashboardTheme);

        if (!defaultTheme) {
          setTheme(dashboard.defaultTheme);
        }

        if (json.roles) {
          setRoles(json.roles);
        }

        if (json.user) {
          setUser(json.user);
        }

        if (json.idleTimeout)
          setIdleTimeout(json.idleTimeout);


        if (dashboard.geolocation) {
          getLocation(setLocation)
        }

        setAuthType(json.authType);
        setSessionId(json.sessionId);

        if (dashboard.sessionTimeout) {
          setModalContent(UniversalDashboard.renderComponent(dashboard.sessionTimeout));
        }
      },
      history,
    )
  }

  useEffect(() => {
    if (dashboard) return

    try {
      loadData()
    } catch (err) {
      setDashboardError(err)
    }
  })

  if (didCatch) {
    return <Alert variant="standard" severity="error">
      <AlertTitle>{`Error rendering component`}</AlertTitle>
      {error}
    </Alert>
  }

  if (dashboardError) {
    return <Alert variant="standard" severity="error">
      <AlertTitle>{`Error with dashboard script`}</AlertTitle>
      <pre>{dashboardError}</pre>
    </Alert>
  }

  if (loading) {
    return <div />
  }

  try {
    var component = UniversalDashboard.renderDashboard({
      dashboard,
      dashboardName,
      history,
      roles,
      user,
      authType,
      headerContent: dashboard.headerContent,
      loadNavigation: dashboard.loadNavigation,
      menu: dashboard.menu
    })

    let dashboardTheme = currentTheme;
    if (currentTheme && theme === "light" && currentTheme.light) {
      dashboardTheme = currentTheme.light;
    }
    else if (currentTheme && theme === "dark" && currentTheme.dark) {
      dashboardTheme = currentTheme.dark;
    }

    const globalStyle = dashboardTheme.globalStyle;

    const muiTheme = createTheme(adaptV4Theme({
      palette: {
        mode: theme
      },
      ...dashboardTheme
    }));

    const appContext = {
      theme,
      setTheme,
      currentTheme,
      setCurrentTheme,
      selectedComponent,
      setSelectedComponent,
      roles,
      developerLicense
    }

    return (
      <AppContext.Provider value={appContext}>
        <ErrorBoundary>

          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
              <GlobalStyles styles={globalStyle} />
              <Helmet htmlAttributes={{
                'data-theme': theme
              }}>
              </Helmet>
              <CssBaseline />
              {/* <DesignerToolbar /> */}
              {component}
              <ReadHostModal
                connection={websocket.current}
                open={readHostOpen}
                setOpen={setReadHostOpen}
                {...readHost}
              />
              <ProgressSnackbar open={progressOpen} value={progress.percentComplete} {...progress} />
              <DebugModal connection={websocket.current} open={debugOpen} setOpen={setDebugOpen} {...debugState} />
            </ThemeProvider>
          </StyledEngineProvider>
          <ReactInterval timeout={5000} enabled={!sessionTimedOut} callback={() => checkSession()} />

        </ErrorBoundary>
      </AppContext.Provider>
    );
  } catch (err) {
    setDashboardError(err)
  }

  return null
}

export default Dashboard
