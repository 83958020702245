import React from 'react';

const Chip = React.lazy(() => import("./chips"));
const Icon = React.lazy(() => import("./icon"));
const Paper = React.lazy(() => import("./paper"));
const IconButton = React.lazy(() => import("./icon-button"));
import { UDList, UDListItem } from "./list";
import { UDButtonGroup, UDButtonGroupItem } from "./button-group";
const UDButton = React.lazy(() => import("./button"));
const UDMuCard = React.lazy(() => import("./card"));
const UDCardMedia = React.lazy(() => import("./card-media"));
const UDCardHeader = React.lazy(() => import("./card-header"));
const UDCardBody = React.lazy(() => import("./card-body"));
const UDCardExpand = React.lazy(() => import("./card-expand"));
const UDCardFooter = React.lazy(() => import("./card-footer"));
const Typography = React.lazy(() => import("./typography"));
const UDLink = React.lazy(() => import("./link"));
const UDAvatar = React.lazy(() => import("./avatar"));
const UDAvatarGroup = React.lazy(() => import("./avatar-group"));
const UDCheckBox = React.lazy(() => import("./checkbox"));
const Progress = React.lazy(() => import('./progress'));
const AccordionGroup = React.lazy(() => import('./expansion-panel'));
const FloatingActionButton = React.lazy(() => import('./floating-action-button'));
const Tabs = React.lazy(() => import('./tabs'));
const Grid = React.lazy(() => import('./grid'));
const Transition = React.lazy(() => import('./transition'));
const Table = React.lazy(() => import('./table/v2/table'));
const Select = React.lazy(() => import('./select'));
const Textbox = React.lazy(() => import('./textbox'));
const UDSwitch = React.lazy(() => import('./switch'));
const UDTreeView = React.lazy(() => import('./treeview'));
const UDDynamic = React.lazy(() => import('./dynamic'));
const UDForm = React.lazy(() => import('./form'));
const UDDatePicker = React.lazy(() => import('./datepicker'));
const UDTimePicker = React.lazy(() => import('./timepicker'));
const UDNavbar = React.lazy(() => import('./framework/ud-navbar'));
const UDAppBar = React.lazy(() => import('./appbar'));
const UDDrawer = React.lazy(() => import('./drawer'));
import { UDRadioGroupWithContext, UDRadio } from './radio';
const UDContainer = React.lazy(() => import('./container'));
const UDAutocomplete = React.lazy(() => import('./autocomplete'));
const UDErrorCard = React.lazy(() => import('./framework/error-card'));
import { UDStep, UDStepper } from './stepper';
const UDSlider = React.lazy(() => import('./slider'))
const UDUpload = React.lazy(() => import('./upload'))
const DateTime = React.lazy(() => import('./datetime'));
const UDAlert = React.lazy(() => import('./alert'));
const UDSkeleton = React.lazy(() => import('./skeleton'));
const UDBackdrop = React.lazy(() => import('./backdrop'));
const UDHidden = React.lazy(() => import('./hidden'));
const UDTransferList = React.lazy(() => import('./transfer-list'));
const UDMenu = React.lazy(() => import('./menu'));
const UDMenuItem = React.lazy(() => import('./menu-item'));
const UDStyle = React.lazy(() => import('./style'));
const UDMonacoEditor = React.lazy(() => import('./code-editor'));
const UDEditor = React.lazy(() => import('./editor'));
const GridLayout = React.lazy(() => import('./grid-layout'));
const UDStack = React.lazy(() => import('./stack'));
const SchemaForm = React.lazy(() => import('./schema-form'));
const UDTimeline = React.lazy(() => import('./timeline'));
const UDBadge = React.lazy(() => import('./badge'));
const UDDataGrid = React.lazy(() => import('./datagrid'));
const UDRating = React.lazy(() => import('./rating'));
const UDSyntaxHighlighter = React.lazy(() => import('./syntax-highlighter'));
const UDMarkdown = React.lazy(() => import('./markdown'));
const UDDivider = React.lazy(() => import('./divider'));
const UDToggleButton = React.lazy(() => import('./togglebutton'));
const UDSpeeddial = React.lazy(() => import('./speeddial'));
const UDHelmet = React.lazy(() => import('./helmet'));
const UDBreadcrumbs = React.lazy(() => import('./breadcrumbs'));
const DateRangePicker = React.lazy(() => import('./date-range'));

UniversalDashboard.register("ud-grid-layout", GridLayout);
UniversalDashboard.register("mu-chip", Chip);
UniversalDashboard.register("mu-icon", Icon);
UniversalDashboard.register("mu-paper", Paper);
UniversalDashboard.register("mu-icon-button", IconButton);
UniversalDashboard.register("mu-list", UDList);
UniversalDashboard.register("mu-list-item", UDListItem);
UniversalDashboard.register("mu-button", UDButton);
UniversalDashboard.register("mu-button-group", UDButtonGroup);
UniversalDashboard.register("mu-button-group-item", UDButtonGroupItem);
UniversalDashboard.register("mu-card", UDMuCard);
UniversalDashboard.register("mu-card-media", UDCardMedia);
UniversalDashboard.register("mu-card-header", UDCardHeader);
UniversalDashboard.register("mu-card-body", UDCardBody);
UniversalDashboard.register("mu-card-expand", UDCardExpand);
UniversalDashboard.register("mu-card-footer", UDCardFooter);
UniversalDashboard.register("mu-typography", Typography);
UniversalDashboard.register("mu-link", UDLink);
UniversalDashboard.register("mu-avatar", UDAvatar);
UniversalDashboard.register("mu-avatar-group", UDAvatarGroup);
UniversalDashboard.register("mu-checkbox", UDCheckBox);
UniversalDashboard.register("mu-progress", Progress);
UniversalDashboard.register('mu-expansion-panel-group', AccordionGroup);
UniversalDashboard.register('mu-fab', FloatingActionButton);
UniversalDashboard.register('mu-tabs', Tabs);
UniversalDashboard.register('mu-grid', Grid);
UniversalDashboard.register('mu-table', Table);
UniversalDashboard.register('mu-select', Select);
UniversalDashboard.register('mu-textbox', Textbox);
UniversalDashboard.register('mu-switch', UDSwitch);
UniversalDashboard.register('mu-treeview', UDTreeView);
UniversalDashboard.register('dynamic', UDDynamic);
UniversalDashboard.register('mu-form', UDForm);
UniversalDashboard.register('mu-datepicker', UDDatePicker);
UniversalDashboard.register('mu-timepicker', UDTimePicker);
UniversalDashboard.register('ud-navbar', UDNavbar);
UniversalDashboard.register('mu-appbar', UDAppBar);
UniversalDashboard.register('mu-drawer', UDDrawer);
UniversalDashboard.register('mu-radio', UDRadio);
UniversalDashboard.register('mu-radiogroup', UDRadioGroupWithContext);
UniversalDashboard.register('mu-container', UDContainer);
UniversalDashboard.register("mu-autocomplete", UDAutocomplete);
UniversalDashboard.register("error", UDErrorCard);
UniversalDashboard.register("mu-stepper-step", UDStep);
UniversalDashboard.register("mu-stepper", UDStepper);
UniversalDashboard.register("mu-slider", UDSlider);
UniversalDashboard.register("mu-upload", UDUpload);
UniversalDashboard.register("mu-datetime", DateTime);
UniversalDashboard.register("mu-transition", Transition);
UniversalDashboard.register("mu-alert", UDAlert);
UniversalDashboard.register("mu-skeleton", UDSkeleton);
UniversalDashboard.register("mu-backdrop", UDBackdrop);
UniversalDashboard.register("mu-hidden", UDHidden);
UniversalDashboard.register("mu-transfer-list", UDTransferList);
UniversalDashboard.register("mu-menu", UDMenu);
UniversalDashboard.register("mu-menu-item", UDMenuItem);
UniversalDashboard.register("ud-style", UDStyle);
UniversalDashboard.register("ud-monaco", UDMonacoEditor);
UniversalDashboard.register("ud-editor", UDEditor);
UniversalDashboard.register("mu-stack", UDStack);
UniversalDashboard.register("mu-schema-form", SchemaForm);
UniversalDashboard.register("mu-timeline", UDTimeline);
UniversalDashboard.register("mu-badge", UDBadge);
UniversalDashboard.register("mu-datagrid", UDDataGrid);
UniversalDashboard.register("mu-rating", UDRating);
UniversalDashboard.register("mu-syntax-highlighter", UDSyntaxHighlighter);
UniversalDashboard.register("mu-markdown", UDMarkdown);
UniversalDashboard.register("mu-divider", UDDivider);
UniversalDashboard.register("mui-toggle-button-group", UDToggleButton);
UniversalDashboard.register("mu-speeddial", UDSpeeddial);
UniversalDashboard.register("mu-helmet", UDHelmet);
UniversalDashboard.register("mu-breadcrumbs", UDBreadcrumbs);
UniversalDashboard.register("mui-date-range-picker", DateRangePicker);