import React from 'react';

export const PageContext = React.createContext({
    rootClass: "",
    displayHeader: true,
    headerContent: null,
    navigation: [],
    navigationLayout: "permanent",
    pages: [],
    user: "",
    logo: null,
    disableThemeToggle: false,
    title: "",
    headerColor: null,
    headerBackgroundColor: null,
    headerPosition: "",
    hideUserName: false,
    blank: false,
    disableThemeToggle: false
});