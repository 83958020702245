import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import makeStyles from '@mui/styles/makeStyles';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import classNames from "classnames"
import { withComponentFeatures } from 'universal-dashboard';
import { useHistory, useRouteMatch, matchPath, useLocation } from "react-router-dom";
import UDSwitch from './switch';
import UDCheckBox from './checkbox';
import { NavBarContext } from "./framework/navbar-context";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  item: {
    marginBottom: theme.spacing()
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export const UDListItem = (props) => {
  return (
    <NavBarContext.Consumer>
      {(context) => {
        return <InternalUDListItem {...props} {...context} />
      }}
    </NavBarContext.Consumer>
  )
}

const ApplyDense = (children, dense) => {
  if (Array.isArray(children)) {
    children.forEach(element => {
      element.dense = dense;
    });
  }
  else {
    children.dense = dense;
  }
}

const findOpenChildren = (children, location) => {
  if (!children) return false;
  for (var i = 0; i < children.length; i++) {
    if (matchPath(location.pathname, { path: children[0].href, exact: true })) return true;
    if (children[i].children) {
      if (findOpenChildren(children[i].children, location)) return true;
    }
  }
  return false;
}

const InternalUDListItem = withComponentFeatures(props => {
  const classes = useStyles();
  const location = useLocation();
  const matchedChild = props.children && Array.isArray(props.children) && findOpenChildren(props.children, location);
  const [open, setOpen] = useState(props.open || matchedChild);
  const navigate = useHistory();
  const match = useRouteMatch(props.href);

  var avatar = null;

  if (props.avatarType === 'Icon' && props.icon) {
    avatar = <ListItemIcon>{props.icon ? props.render(props.icon) : null}</ListItemIcon>
  }

  if (props.avatarType === 'Avatar' && props.source) {
    avatar = <ListItemAvatar><Avatar src={props.source} /></ListItemAvatar>
  }

  var secondaryItem = null;
  if (props.secondaryAction) {
    secondaryItem = <ListItemSecondaryAction>{props.render(props.secondaryAction)}</ListItemSecondaryAction>
  }

  const clickable = props.href || props.onClick || props.children;

  const onClick = () => {
    if (props.href) {
      if (props.href.startsWith('/')) {
        navigate.push(props.href);
      } else if (props.openInNewWindow) {
        window.open(props.href, '_blank');
      } else {
        window.location.href = props.href;
      }
      if (props.toggleNavBar) props.toggleNavBar();
    }
    if (props.onClick) { props.onClick() }
    if (props.children) { setOpen(!open); }
  }

  var expand = null;
  var collapse = null;

  if (props.children) {
    ApplyDense(props.children, props.dense);
    expand = open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />;
    collapse = <Collapse in={open} timeout="auto" unmountOnExit mountOnEnter >
      <List component="div" disablePadding dense={props.dense}>
        {props.render(props.children)}
      </List>
      <Divider />
    </Collapse>
  }

  return [
    <ListItem
      button={clickable}
      key={props.id}
      id={props.id}
      onClick={clickable ? onClick : null}
      style={{ cursor: clickable ? 'pointer' : 'default', ...props.style }}
      className={classNames(props.className, props.nested && classes.nested)}
      sx={props.sx}
      selected={props.href && match}
    >
      {avatar}
      {props.switch && props.switchAlignment === 'left' ? <UDSwitch {...props.switch} /> : null}
      {props.checkBox && props.checkBoxAlignment === 'left' ? <UDCheckBox {...props.checkBox} /> : null}
      <ListItemText primary={props.label && props.render(props.label)} secondary={props.subTitle} />
      {props.switch && props.switchAlignment === 'right' ? <UDSwitch {...props.switch} /> : null}
      {props.checkBox && props.checkBoxAlignment === 'right' ? <UDCheckBox {...props.checkBox} /> : null}
      {secondaryItem}
      {expand}
    </ListItem>,
    collapse
  ];
})

export const UDList = withComponentFeatures(props => {
  const classes = useStyles();
  const { children, sx } = props;
  ApplyDense(children, props.dense);
  return (
    <List
      id={props.id}
      key={props.id}
      dense={props.dense}
      subheader={
        <ListSubheader disableSticky>{props.subHeader}</ListSubheader>
      }
      className={classNames(classes.root, "ud-mu-list", props.className)}
      component="div"
      sx={sx}
    >
      {props.render(children)}
    </List>
  );
})