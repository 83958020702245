import React from 'react';
import { getApiPath, getDashboardId } from './../config.jsx'
import renderComponent from './../services/render-service.jsx';
import useErrorBoundary from "use-error-boundary"

const LazyElement = (props) => {
    const [loading, setLoading] = React.useState(true);

    const {
        ErrorBoundary,
        didCatch,
        error
    } = useErrorBoundary()

    React.useEffect(() => {
        if (props.component.type === 'error') {
            return;
        }

        const dashboardId = getDashboardId();

        var script = document.createElement('script');
        script.onload = function () {
            setLoading(false);
        }.bind(this)
        script.src = getApiPath() + `/api/internal/javascript/${props.component.assetId}?dashboardId=${dashboardId}`;
        document.head.appendChild(script);
    }, [props.component.assetId])

    if (loading) {
        return <div></div>;
    }

    if (props.component.type === 'error') {
        return <div>{props.component.message}</div>
    }

    if (didCatch) {
        return renderComponent({
            type: 'error',
            errorRecords: [
                {
                    message: `There was an error rendering component of type ${props.component.type}. ${error}`
                }
            ]
        });
    }

    var element = renderComponent(props.component, props.history, true);

    if (element == null) {
        return renderComponent({
            type: 'error',
            message: `Component not registered: ${props.component.type}`
        });
    }

    return <ErrorBoundary>{element}</ErrorBoundary>;
}

export default LazyElement;