import { useEffect, useRef } from 'react';

export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}


export function intersection(arr1, arr2) {
  const res = [];
  const { length: len1 } = arr1;
  const { length: len2 } = arr2;
  const smaller = (len1 < len2 ? arr1 : arr2).slice();
  const bigger = (len1 >= len2 ? arr1 : arr2).slice();
  for (let i = 0; i < smaller.length; i++) {
    if (bigger.indexOf(smaller[i]) !== -1) {
      res.push(smaller[i]);
      bigger.splice(bigger.indexOf(smaller[i]), 1, undefined);
    }
  };
  return res;
};