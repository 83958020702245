import React from 'react';
import UdDashboard from './ud-dashboard.jsx'
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'
import { CompatRouter } from "react-router-dom-v5-compat";
import NotAuthorized from '../Components/framework/not-authorized.jsx';
import NotRunning from '../Components/framework/not-running.jsx';
import { Alert, AlertTitle } from "@mui/material";
import { QueryClient, QueryClientProvider } from 'react-query'
import useErrorBoundary from "use-error-boundary"
import { SnackbarProvider } from 'notistack'

const queryClient = new QueryClient();
const App = () => {
    const {
        ErrorBoundary,
        didCatch,
        error
    } = useErrorBoundary()

    if (didCatch) {
        return <Alert variant="standard" severity="error">
            <AlertTitle>{`Error rendering dashboard`}</AlertTitle>
            <p>This error is not expected. Please contact Ironman Software support.</p>
            <p>{error && error.toString()}</p>
        </Alert>
    }

    return (
        <ErrorBoundary>
            <React.StrictMode>
                <SnackbarProvider>
                    <QueryClientProvider client={queryClient}>
                        <Router basename={window.baseUrl}>
                            <CompatRouter>
                                <div className="ud-dashboard">
                                    <Switch>
                                        <Route path="/not-authorized" component={NotAuthorized} />
                                        <Route path="/not-running" component={NotRunning} />
                                        <Route path="/" component={UdDashboard} />
                                    </Switch>
                                </div>
                            </CompatRouter>
                        </Router>
                    </QueryClientProvider>
                </SnackbarProvider>
            </React.StrictMode>
        </ErrorBoundary>

    )
}


export default App;