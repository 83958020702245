import React, { Suspense, useState } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import '@fontsource/roboto';

LicenseInfo.setLicenseKey('a9c998bffebe0487a0cd13a3079538ebTz03MjQ4OSxFPTE3MjMyOTU4MDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

import { withComponentFeatures } from 'universal-dashboard';

import { PageContext } from "./../app/PageContext";
import makeStyles from '@mui/styles/makeStyles';
import NotAuthorized from './framework/not-authorized';
import { intersection } from './utilities';

const NotFound = React.lazy(() => import('./framework/not-found'));
const UDNavbar = React.lazy(() => import('./framework/ud-navbar'));

require('./components');
require('./map/index');
require('./charts/index');

const LayoutEditor = React.lazy(() => import('./../app/designer/layout-editor'));

import { matchPath, useLocation } from "react-router-dom";

import {
    Route,
    Redirect,
    Switch
} from 'react-router-dom'

// Framework Support
import UdPage from './framework/ud-page';
import UDModal from './framework/ud-modal';
import Skeleton from './skeleton';

function getDefaultHomePage(pages) {
    return pages.find(function (page) {
        return page.defaultHomePage === true;
    });
}

function redirectToHomePage(pages) {

    if (pages == null || pages.length == 0) {
        window.location.href = "/unauthorized"
    }

    var defaultHomePage = getDefaultHomePage(pages);

    if (defaultHomePage == null) {
        defaultHomePage = pages[0];
    }

    if (defaultHomePage.url != null && defaultHomePage.url.indexOf(":") === -1) {
        return <Redirect to={defaultHomePage.url} />
    }
    else {
        return <Suspense fallback={<div></div>}>
            <UDErrorCard message="Your first page needs page without a variable in the URL." />
        </Suspense>
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100vh',
        display: props => props.navigationLayout === "permanent" ? 'flex' : null
    },
    main: {
        flexGrow: 1,
        padding: theme.spacing(3)
    }
}));


const MaterialUI = (props) => {
    var { dashboard, roles, user, menu, loadNavigation, dashboardName } = props;

    const location = useLocation();
    const inDesigner = matchPath(location.pathname, { path: "/designer", exact: true })

    var authedPages = dashboard.pages.filter(x => {
        if (!x.role) return true;
        if (!Array.isArray(x.role)) return true;
        if (!roles || roles.length == 0) return false;
        if (!Array.isArray(roles)) return false;
        if (intersection(roles, x.role).length == 0) return false;
        return true;
    });

    const [pageState, setPageState] = useState({
        displayHeader: false,
        blank: false,
        pages: authedPages,
        user,
        title: dashboard.title,
        hideUserName: dashboard.hideUserName,
        disableThemeToggle: dashboard.disableThemeToggle,
        navigationLayout: dashboard.navigationLayout,
        roles
    });

    const classes = useStyles({ navigationLayout: pageState.navigationLayout || dashboard.navigationLayout });

    var pages = authedPages.map(function (x) {
        if (!x.url) {
            x.url = x.name;
        }

        if (!x.url.startsWith("/")) {
            x.url = "/" + x.url;
        }

        return <Route key={x.url} path={x.url} exact={x.url.indexOf(":") === -1} render={pageProps => (
            <UdPage
                id={x.id}
                dynamic={true}
                {...x}
                {...pageProps}
                setPageState={setPageState}
                key={pageProps.location.key}
            />
        )} />
    })

    var unauthedPages = dashboard.pages.filter(x => authedPages.filter(y => y.url === x.url).length === 0);
    if (dashboard.notAuthorized) {
        unauthedPages.forEach(x => {
            pages.push(<Route key={x.url} path={x.url} exact={x.url.indexOf(":") === -1} render={() => <NotAuthorized notAuthorized={dashboard.notAuthorized} />} />)
        })
    }

    const loadNav = React.useCallback(() => {
        if (loadNavigation) {
            loadNavigation().then(loadNavigationContent => {
                if (pageState.navigation) return;
                const navigation = JSON.parse(loadNavigationContent);
                setPageState(pageState => {
                    return {
                        ...pageState,
                        navigation: pageState.navigation || navigation,
                        displayHeader: true
                    }
                });
            });
        }
    }, [loadNavigation]);

    React.useEffect(async () => {
        loadNav()
    }, []);

    return <PageContext.Provider value={pageState}>
        <Suspense fallback={<Skeleton />}>
            <div className={classes.root}>
                {!inDesigner && <UDNavbar history={props.history} id="defaultNavbar" menu={menu} />}
                <Switch>
                    {pages}
                    <Route path="/designer/:page" render={() => <LayoutEditor dashboardName={dashboardName} />} />
                    <Route exact path={`/`} render={() => redirectToHomePage(authedPages)} />
                    <Route path={`/`} render={() => <NotFound pageNotFound={dashboard.pageNotFound} />} />

                </Switch>
                <UDModal />
            </div>
        </Suspense>
    </PageContext.Provider >
}

const MaterialUIWithFeatures = withComponentFeatures(MaterialUI);

UniversalDashboard.renderDashboard = (props) => <Suspense fallback={<Skeleton />}><MaterialUIWithFeatures {...props} /></Suspense>;