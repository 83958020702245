import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function Modal(props) {

    const [openToken, setOpenToken] = useState();
    const [closeToken, setCloseToken] = useState();
    const [open, setOpen] = useState(false);
    const [modalOptions, setModalOptions] = useState({});

    useEffect(() => {
        setOpenToken(UniversalDashboard.subscribe('modal.open', (x, props) => {
            setModalOptions(props);
            setOpen(true);
        }));

        setCloseToken(UniversalDashboard.subscribe('modal.close', () => {
            setOpen(false);
            setModalOptions({});
        }));

        return () => {
            UniversalDashboard.unsubscribe(openToken);
            UniversalDashboard.unsubscribe(closeToken);
        }
    }, [true]);

    var header = null;
    if (modalOptions.header != null) {
        header = <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)} style={modalOptions.headerStyle}>
            {UniversalDashboard.renderComponent(modalOptions.header)}
        </DialogTitle>
    }

    var content = null;
    if (modalOptions.content != null) {
        content = <DialogContent dividers={modalOptions.dividers} style={modalOptions.contentStyle}>
            {UniversalDashboard.renderComponent(modalOptions.content)}
        </DialogContent>
    }

    var footer = null;
    if (modalOptions.footer != null) {
        footer = <DialogActions style={modalOptions.footerStyle}>{UniversalDashboard.renderComponent(modalOptions.footer)}</DialogActions>
    }

    var paperProps = {
        style: modalOptions.paperStyle
    }

    if (modalOptions.fullHeight) {
        paperProps = {
            style: {
                height: 'calc(100% - 64px)',
                ...modalOptions.paperStyle
            }
        };
    }

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            onBackdropClick={() => modalOptions.dismissible && setOpen(false)}
            maxWidth={modalOptions.maxWidth}
            fullScreen={modalOptions.fullScreen}
            fullWidth={modalOptions.fullWidth}
            style={modalOptions.style}
            PaperProps={paperProps}
        >
            {header}
            {content}
            {footer}
        </Dialog>
    )
} 