import React, { useEffect } from 'react';
import { withComponentFeatures } from 'universal-dashboard';
import { FormContext } from './form';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export const UDRadioGroupWithContext = withComponentFeatures((props) => {
    return (
        <FormContext.Consumer>
            {
                ({ onFieldChange }) => <UDRadioGroup {...props} onFieldChange={onFieldChange} />
            }
        </FormContext.Consumer>
    )
})

const UDRadioGroup = (props) => {
    const disabled = React.useRef(false);

    const onChange = (event) => {
        props.onFieldChange({ id: props.id, value: event.target.value })
        props.setState({ value: event.target.value })

        if (props.onChange) {
            props.onChange(event.target.value);
        }
    }

    let value = props.value;
    if (!value || value === "") {
        value = props.defaultValue;
    }

    if (disabled.current !== props.disabled) {
        props.children.forEach(x => x.disabled = props.disabled);
        disabled.current = props.disabled;
    }

    useEffect(() => {
        props.onFieldChange({ id: props.id, value });

        //why?
        setTimeout(() => props.setState({ value }), 0);

        return () => { }
    }, [value])

    if (props.label) {
        return <FormControl>
            <FormLabel>{props.label}</FormLabel>
            <RadioGroup id={props.id} value={value} onChange={e => onChange(e)}>
                {props.render(props.children)}
            </RadioGroup>
        </FormControl>
    }

    return (
        <RadioGroup id={props.id} value={value} onChange={e => onChange(e)}>
            {props.render(props.children)}
        </RadioGroup>
    )
}

export const UDRadio = (props) => {
    return (
        <FormControlLabel {...props} control={<Radio />}></FormControlLabel>
    )
}