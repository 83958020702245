import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import { DialogTitle, MenuItem } from '@mui/material';

export default function ReadHostModal(props) {
    const { connection } = props;
    const [value, setValue] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleClose = (submit) => {
        if (props.type === "ReadHost" || props.type === "PromptForChoice") {
            connection.invoke('getState', props.id, submit ? value : null)
        } else {
            connection.invoke('getState', props.id, submit ? JSON.stringify({
                UserName: value || props.userName,
                Password: password
            }) : "{}");
        }

        props.setOpen(false);
        setValue('');
        setPassword('');
    };

    React.useEffect(() => {
        if (props.type === "PromptForChoice") {
            setValue(new String(props.defaultChoice))
        }
    }, [props.id])

    const fields = [];
    if (props.type === "ReadHost") {
        fields.push(<TextField
            onChange={e => setValue(e.target.value)}
            autoFocus
            margin="dense"
            key="id"
            id="value"
            fullWidth
            variant="standard"
        />)
    } else if (props.type === "PromptForChoice") {
        const choices = props.choices.map((choice, i) => {
            return <MenuItem value={i} key={`prompt${i}`}>
                <Typography>{choice.label}</Typography>
                {choice.helpMessage && <Typography variant="subtitle1">{choice.helpMessage}</Typography>}
            </MenuItem>
        })
        fields.push(
            <Select
                fullWidth
                onChange={e => setValue(e.target.value.toString())}
                defaultValue={props.defaultChoice}
                id="value"
                key="value"
                variant="standard"
            >{choices}</Select>
        )
    } else {
        fields.push(<TextField
            onChange={e => setValue(e.target.value)}
            autoFocus
            margin="dense"
            id="value"
            key="value"
            fullWidth
            variant="standard"
            label="User Name"
            value={value || props.userName}
        />)
        fields.push(<TextField
            onChange={e => setPassword(e.target.value)}
            margin="dense"
            id="value"
            key="value"
            fullWidth
            variant="standard"
            label="Password"
            type="password"
        />)
    }

    return (
        <Dialog open={props.open} onClose={() => handleClose(false)}>
            {props.title && <DialogTitle>{props.title}</DialogTitle>}
            <DialogContent>
                <Typography gutterBottom>
                    {props.description}
                </Typography>
                {fields}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button onClick={() => handleClose(true)}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}