import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Drawer from '@mui/material/Drawer';
import { Skeleton, Tooltip } from '@mui/material';

import { DataGridPro } from '@mui/x-data-grid-pro';
import { PlayArrowOutlined } from '@mui/icons-material';
import { useEffect } from 'react';

function VariablesTable({ variables }) {
    if (!variables) {
        return <Skeleton />
    }

    debugger

    const cols = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'displayValue',
            headerName: 'Value',
            flex: 1
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1
        }
    ]

    return <DataGridPro density='compact' pagination pageSize={5} columns={cols} getRowId={(row) => row.id} rows={variables.filter(x => x.name)} treeData getTreeDataPath={(row) => row.path.split('/')} />;
}


export default function DebugModal(props) {

    const { connection, Id, setOpen, open, Variables } = props;

    useEffect(() => {
        if (Id) {
            connection.invoke('debuggerWatiting', Id);
        }
        return () => { }
    }, [connection, Id]);

    const handleContinue = () => {
        connection.invoke('continue', Id);
        setOpen(false);
    };

    return (
        <Drawer
            variant="permanent"
            anchor="bottom"
        >
            <div style={{ height: 400, display: open ? 'block' : 'none' }}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ margin: '5px' }}>
                    <Tooltip title='Continue'>
                        <Button onClick={handleContinue}><PlayArrowOutlined /></Button>
                    </Tooltip>
                </ButtonGroup>
                <VariablesTable variables={Variables} />
            </div>
        </Drawer>

    );
}