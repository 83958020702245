import React, { useEffect } from 'react';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { withComponentFeatures } from 'universal-dashboard';
import { FormContext } from './form';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

const UDSwitchWithContext = (props) => {
    return (
        <FormContext.Consumer>
            {
                ({ onFieldChange }) => <UDSwitch {...props} onFieldChange={onFieldChange} />
            }
        </FormContext.Consumer>
    )
}

const UDSwitch = (props) => {

    const onChange = (event) => {
        props.onFieldChange({ id: props.id, value: event.target.checked })
        props.setState({ checked: event.target.checked });

        if (props.onChange) {
            props.onChange(event.target.checked)
        }
    }

    useEffect(() => {
        props.onFieldChange({ id: props.id, value: props.checked });
        return () => { }
    }, true)

    const switchC = <Switch
        className={props.className}
        color={props.color}
        key={props.key}
        id={props.id}
        checked={props.checked}
        onChange={event => onChange(event)}
        disabled={props.disabled}
        size={props.size}
    />

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
            },
            '&:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            '&:after': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 12,
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
        },
    }));

    const SmallSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-track': {
            height: 10,
        },
        '& .MuiSwitch-thumb': {
            marginLeft: '-2px !important',
            marginTop: '2px !important',
        },
    }));

    var switchStyled = switchC;

    if (props.checkStyle) {
        switchStyled = <Android12Switch
            className={props.className}
            color={props.color}
            key={props.key}
            id={props.id}
            checked={props.checked}
            onChange={event => onChange(event)}
            disabled={props.disabled}
            size={props.size}
        />
    }

    if (props.size == "small") {
        switchStyled = <SmallSwitch
            className={props.className}
            color={props.color}
            key={props.key}
            id={props.id}
            checked={props.checked}
            onChange={event => onChange(event)}
            disabled={props.disabled}
            size={props.size}
        />
    }

    if (props.labelPlacement && !props.uncheckedLabel && !props.checkedLabel) {
        return <FormControlLabel
            control={switchStyled}
            label={props.label}
            labelPlacement={props.labelPlacement}
        />
    }

    return props.checkedLabel || props.uncheckedLabel || props.label ? (
        <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>{props.uncheckedLabel}</Grid>
            <Grid item>
                {switchStyled}
            </Grid>
            <Grid item>{props.checkedLabel || props.label}</Grid>
        </Grid>
    ) : switchStyled
}

export default withComponentFeatures(UDSwitchWithContext);

