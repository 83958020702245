import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { withComponentFeatures } from 'universal-dashboard'

const UDTooltip = (props) => {
    let color = null;
    let fontColor = null;
    switch (props.tooltipType) {
        case "success":
            color = "#8dc572";
            break;
        case "error":
            color = "#be6464";
            break;
        case "warning":
            color = "#f0ad4e";
            break;
        case "info":
            color = "#337ab7";
            break;
        case "light":
            color = "#fff";
            fontColor = "#222";
            break;
    }

    return <Tooltip
        title={props.render(props.tooltipContent)}
        id={props.id}
        placement={props.placement}
        sx={props.sx}
        arrow={props.arrow}
        followCursor={props.followCursor}
        enterDelay={props.enterDelay}
        leaveDelay={props.leaveDelay}
        componentsProps={{
            tooltip: {
                sx: {
                    ...props.sx,
                    backgroundColor: color,
                    color: fontColor
                },

            }
        }}
    >
        <span>
            {props.render(props.content)}
        </span>
    </Tooltip>
}

export default withComponentFeatures(UDTooltip)