import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function CircularProgressWithLabel(
    props
) {
    if (props.value === -1) {
        return <CircularProgress variant={"indeterminate"} {...props} />
    }

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant={"determinate"} {...props} />
            <Box
                sx={{
                    top: -4,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function ProgressSnackbar(props) {
    return <Snackbar open={props.value < 100 && props.open}>
        <Alert severity="info" icon={false} >
            <Stack direction={"row"}>
                <CircularProgressWithLabel value={props.value} />
                <Stack direction={"column"} style={{ marginLeft: '10px', verticalAlign: 'center' }}>
                    <Typography>{props.activity}</Typography>
                    {props.statusDescription && <Typography variant="caption">{props.statusDescription}</Typography>}
                    {props.operation && <Typography variant="caption">{props.operation}</Typography>}
                </Stack>
            </Stack>
        </Alert>
    </Snackbar>
}              