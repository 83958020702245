import { getApiPath, getDashboardId } from './config.jsx'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default function useWebSocket(sessionId, pageId, onSuccess, configure) {
    const dashboardId = getDashboardId();

    let url = `/dashboardhub?dashboardid=${dashboardId}&pageid=${pageId}&sessionid=${sessionId}&timezone=${timezone}`;

    const connection = new HubConnectionBuilder()
        .withUrl(getApiPath() + url)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build()

    configure(connection);

    connection.start().then(x => {
        window.UniversalDashboard.webSocket = connection
        onSuccess();
    })

    connection.onreconnecting((error) => {
        console.log("Web socket reconnecting: " + error.message);
    })

    return connection;
}